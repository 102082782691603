import React from "react"
import Typed from "react-typed"
import { Link } from "gatsby"
import { FiArrowRight } from "react-icons/fi"
import AccordionOne from "../accordion/AccordionOne"

const mission = () => {
  return (
    <div className="rn-company-mission-are rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-6">
            <div className="mission-title">
              <h2 className="title">
                Industry Expertise <br />{" "}
              </h2>
              <p>
                At Medina-digital LLC, we specialize in crafting exceptional
                ecommerce solutions tailored specifically for Shopify. Our team
                of seasoned developers is dedicated to staying ahead of the
                curve, ensuring your online store is not just a platform, but a
                powerful engine driving your business success
              </p>
            </div>
          </div>
          <div className="col-lg-6 mt_md--30 mt_sm--30">
            <AccordionOne customStyle="" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default mission
