import React from "react"
import Typed from "react-typed"
import SEO from "../common/SEO"
import { Link } from "gatsby"
import { FiArrowRight } from "react-icons/fi"
import HeaderTwo from "../common/header/HeaderTwo"
import Brand from "../elements/brand/Brand"
import Slider from "react-slick"
import Copyright from "../common/footer/Copyright"
import AboutOne from "../elements/about/AboutOne"
import { BannerActivation } from "../utils/script"
import CalltoActionEight from "../elements/calltoaction/CalltoActionEight"
import ServiceFive from "../elements/service/ServiceFive"
import SectionTitle from "../elements/sectionTitle/SectionTitle"

import Separator from "../elements/separator/Separator"
import Mission from "../elements/about/Mission"

const BannerData = [
  {
    image:
      "https://rainbowit.net/html/doob/assets/images/call-to-action/bg-image-1.jpg",
    title: "Medina Digital.",
    description:
      "Welcome to Medina-digital, your premier destination for cutting-edge Shopify development! With a wealth of experience spanning several years, we take pride in being experts in the ever-evolving world of ecommerce.",
  },
]

const BusinessConsulting2 = () => {
  return (
    <>
      <SEO title="Medina Digital" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <Slider
          className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
        >
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div
                className="height-950 bg-overlay bg_image"
                style={{
                  backgroundImage: `url(${data.image})`,
                }}
              >
                <div className="container">
                  <div className="row row--30 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-7">
                      <div className="inner text-start">
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h1>
                        <p
                          className="description"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                        <div className="button-group mt--30">
                          <Link className="btn-default" to="/contact">
                            Contact
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {/* End Slider Area  */}
        <Separator />

        {/* Start About Area  */}
        {/* <AboutOne /> */}
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Services"
                  description=""
                />
              </div>
            </div>
            <ServiceFive serviceStyle="gallery-style" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}
        <Brand />
        {/* End Slider Area  */}

        <Separator />
        <Mission />
        {/* End About Area  */}
        <Separator />
        <CalltoActionEight
          callToActionData={{
            title: "Let's Elevate Your Ecommerce Presence",
            subtitle:
              "Whether you're starting from scratch or looking to enhance your existing Shopify store, Medina-digital LLC is here to transform your vision into a reality. Join the ranks of successful brands that have entrusted us with their ecommerce development, and let's take your online business to new heights.Ready to embark on the next chapter of your ecommerce journey? Contact us today, and let's build something extraordinary together!",
            btnText: "Contact",
          }}
        />
        <Separator />

        {/* <FooterTwo />*/}
        <Copyright />
      </main>
    </>
  )
}
export default BusinessConsulting2
