import React from "react"
import { Link } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import sp from "../../sp.png"
import sapp from "../../sapp.png"
import njs from "../../njs.png"
import rjs from "../../rjs.png"

const ServiceList = [
  {
    image: sp,
    title: "Shopify Storefront development",
    description: "Custom theme development, app integrations, and more.",
  },
  {
    image: rjs,
    title: "Landing pages & funnels",
    description: "Custom landing page developement, A/B testing, and more.",
  },
  {
    image: sapp,
    title: "App Development",
    description:
      "Custom Shopify App development using the latest in Shopify Function API's and Checkout API's.",
  },
]
const ServiceFive = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="inner">
                <div className="content">
                  <h4 className="title">
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                </div>
                <div className="image service-image">
                  <img src={`${val.image}`} alt="card Images" />
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  )
}
export default ServiceFive
