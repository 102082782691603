import React from "react"
import ironman from "../../ironman.png"
import echelon from "../../ellc.png"
import km from "../../km.png"
import tb from "../../tb.png"
import rr from "../../rr.png"
import ce from "../../ce.png"
const BrandList = [
  {
    image: ironman,
  },
  {
    image: echelon,
  },
  {
    image: km,
  },
  {
    image: tb,
  },
  {
    image: rr,
  },
  {
    image: ce,
  },
]

const BrandOne = ({ brandStyle }) => {
  return (
    <ul className={`brand-list ${brandStyle}`}>
      {BrandList.map((data, index) => (
        <li key={index}>
          <a>
            <img src={`${data.image}`} alt="Brand Image" />
          </a>
        </li>
      ))}
    </ul>
  )
}

export default BrandOne
