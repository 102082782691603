import React from "react"
import { Accordion, Card, Button } from "react-bootstrap"

const AccordionOne = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey="0"
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Proven Experience
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            With a track record of success, we've collaborated with numerous
            esteemed brands, helping them achieve their ecommerce goals. Our
            portfolio is a testament to the diverse range of projects we've
            undertaken, showcasing our ability to deliver results that exceed
            expectation
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            Tailored Solutions
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            No two businesses are alike, and we understand the importance of
            customization. Our team works closely with you to understand your
            unique requirements and business objectives, delivering a Shopify
            solution that is tailored to your brand's needs.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Innovation at Every Step
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            Ecommerce is a dynamic landscape, and we thrive on innovation. From
            user-friendly interfaces to seamless payment integrations, we
            implement the latest technologies to keep your online store at the
            forefront of the digital marketplace.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Collaborative Partnership
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            Your success is our success. We believe in building strong,
            collaborative partnerships with our clients. Communication is key,
            and we ensure that you are involved at every stage of the
            development process, from concept to launch.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}
export default AccordionOne
